
export default [
  {
    "id": 1,
    "name": "商品売買業、サービス業の処理",
    "sort": 1,
  },
  {
    "id": 2,
    "name": "現金と預金",
    "sort": 2,
  },
  {
    "id": 3,
    "name": "手形と電子記録債権(債務)、債権譲渡",
    "sort": 3,
  },
  {
    "id": 4,
    "name": "有形固定資産①",
    "sort": 4,
  },
  {
    "id": 5,
    "name": "有形固定資産②",
    "sort": 5,
  },
  {
    "id": 6,
    "name": "リース取引",
    "sort": 6,
  },
  {
    "id": 7,
    "name": "無形固定資産と研究開発費",
    "sort": 7,
  },
  {
    "id": 8,
    "name": "有価証券",
    "sort": 8,
  },
  {
    "id": 9,
    "name": "引当金",
    "sort": 9,
  },
  {
    "id": 10,
    "name": "外貨建取引",
    "sort": 10,
  },
  {
    "id": 11,
    "name": "株式の発行",
    "sort": 11,
  },
  {
    "id": 12,
    "name": "剰余金の配当と処分",
    "sort": 12,
  },
  {
    "id": 13,
    "name": "税金、税効果会計",
    "sort": 13,
  },
  {
    "id": 14,
    "name": "収益認識",
    "sort": 14,
  },
  {
    "id": 15,
    "name": "決算手続き①",
    "sort": 15,
  },
  {
    "id": 16,
    "name": "決算手続き②",
    "sort": 16,
  },
  {
    "id": 17,
    "name": "本支店会計",
    "sort": 17,
  },
  {
    "id": 18,
    "name": "連結会計①",
    "sort": 18,
  },
  {
    "id": 19,
    "name": "連結会計②",
    "sort": 19,
  }
]