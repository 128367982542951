
export default [
  {
    id: "1-1",
    title: "商品売買の処理方法",
    categoryId: 1,
    page: 60
  },
  {
    id: "1-2",
    title: "割戻し",
    categoryId: 1,
    page: 60
  },
  {
    id: "1-3",
    title: "売上原価と期末商品の評価",
    categoryId: 1,
    page: 61
  },
  {
    id: "2-1",
    title: "銀行勘定調整表①",
    categoryId: 2,
    page: 62
  },
  {
    id: "2-2",
    title: "銀行勘定調整表②",
    categoryId: 2,
    page: 63
  },
  {
    id: "3-1",
    title: "手形①",
    categoryId: 3,
    page: 65
  },
  {
    id: "3-2",
    title: "手形②",
    categoryId: 3,
    page: 65
  },
  {
    id: "3-3",
    title: "電子記録債権(債務)、債権譲渡",
    categoryId: 3,
    page: 65
  },
  {
    id: "4-1",
    title: "有形固定資産の割賦購入①",
    categoryId: 4,
    page: 66
  },
  {
    id: "4-2",
    title: "有形固定資産の割賦購入②",
    categoryId: 4,
    page: 66
  },
  {
    id: "4-3",
    title: "減価償却",
    categoryId: 4,
    page: 67
  },
  {
    id: "4-4",
    title: "有形固定資産の売却、買換え",
    categoryId: 4,
    page: 67
  },
  {
    id: "4-5",
    title: "有形固定資産の除却、廃棄",
    categoryId: 4,
    page: 69
  },
  {
    id: "5-1",
    title: "建設仮勘定",
    categoryId: 5,
    page: 70
  },
  {
    id: "5-2",
    title: "有形固定資産の改良と修繕",
    categoryId: 5,
    page: 70
  },
  {
    id: "5-3",
    title: "有形固定資産の滅失",
    categoryId: 5,
    page: 70
  },
  {
    id: "5-4",
    title: "圧縮記帳",
    categoryId: 5,
    page: 71
  },
  {
    id: "6-1",
    title: "ファイナンス・リース取引",
    categoryId: 6,
    page: 71
  },
  {
    id: "6-2",
    title: "ファイナンス・リース取引",
    categoryId: 6,
    page: 72
  },
  {
    id: "6-3",
    title: "オペレーティング・リース取引",
    categoryId: 6,
    page: 73
  },
  {
    id: "7-1",
    title: "無形固定資産",
    categoryId: 7,
    page: 74
  },
  {
    id: "7-2",
    title: "ソフトウェア、研究開発費",
    categoryId: 7,
    page: 74
  },
  {
    id: "7-3",
    title: "無形固定資産とソフトウェア",
    categoryId: 7,
    page: 74
  },
  {
    id: "8-1",
    title: "株式の処理",
    categoryId: 8,
    page: 75
  },
  {
    id: "8-2",
    title: "公社債の処理①",
    categoryId: 8,
    page: 75
  },
  {
    id: "8-3",
    title: "公社債の処理②",
    categoryId: 8,
    page: 76
  },
  {
    id: "8-4",
    title: "有価証券の期末評価①",
    categoryId: 8,
    page: 77
  },
  {
    id: "8-5",
    title: "有価証券の期末評価②",
    categoryId: 8,
    page: 77
  },
  {
    id: "8-6",
    title: "有価証券の期末評価③",
    categoryId: 8,
    page: 78
  },
  {
    id: "8-7",
    title: "有価証券の総合問題",
    categoryId: 8,
    page: 79
  },
  {
    id: "9-1",
    title: "貸倒引当金",
    categoryId: 9,
    page: 82
  },
  {
    id: "9-2",
    title: "修繕引当金",
    categoryId: 9,
    page: 82
  },
  {
    id: "9-3",
    title: "賞与引当金",
    categoryId: 9,
    page: 82
  },
  {
    id: "9-4",
    title: "退職給付引当金",
    categoryId: 9,
    page: 82
  },
  {
    id: "9-5",
    title: "商品保証引当金",
    categoryId: 9,
    page: 83
  },
  {
    id: "10-1",
    title: "外貨建取引",
    categoryId: 10,
    page: 83
  },
  {
    id: "10-2",
    title: "決算時の処理",
    categoryId: 10,
    page: 84
  },
  {
    id: "10-3",
    title: "為替予約①",
    categoryId: 10,
    page: 84
  },
  {
    id: "10-4",
    title: "為替予約②",
    categoryId: 10,
    page: 85
  },
  {
    id: "11-1",
    title: "株式の発行",
    categoryId: 11,
    page: 85
  },
  {
    id: "11-2",
    title: "新株発行の流れ",
    categoryId: 11,
    page: 86
  },
  {
    id: "11-3",
    title: "合併",
    categoryId: 11,
    page: 86
  },
  {
    id: "12-1",
    title: "剰余金の配当と処分",
    categoryId: 12,
    page: 86
  },
  {
    id: "12-2",
    title: "準備金積立額の計算",
    categoryId: 12,
    page: 87
  },
  {
    id: "12-3",
    title: "株主資本等変動計算書",
    categoryId: 12,
    page: 88
  },
  {
    id: "13-1",
    title: "消費税①",
    categoryId: 13,
    page: 90
  },
  {
    id: "13-2",
    title: "消費税②",
    categoryId: 13,
    page: 90
  },
  {
    id: "13-3",
    title: "法人税等",
    categoryId: 13,
    page: 90
  },
  {
    id: "13-4",
    title: "課税所得の計算",
    categoryId: 13,
    page: 91
  },
  {
    id: "13-5",
    title: "税効果会計①",
    categoryId: 13,
    page: 91
  },
  {
    id: "13-6",
    title: "税効果会計②",
    categoryId: 13,
    page: 93
  },
  {
    id: "14-1",
    title: "収益認識の基本",
    categoryId: 14,
    page: 94
  },
  {
    id: "14-2",
    title: "契約資産",
    categoryId: 14,
    page: 94
  },
  {
    id: "14-3",
    title: "売上割戻し",
    categoryId: 14,
    page: 94
  },
  {
    id: "15-1",
    title: "精算表の作成",
    categoryId: 15,
    page: 95
  },
  {
    id: "15-2",
    title: "財務諸表の作成",
    categoryId: 15,
    page: 98
  },
  {
    id: "16-1",
    title: "サービス業における財務諸表の作成",
    categoryId: 16,
    page: 104
  },
  {
    id: "16-2",
    title: "製造業における財務諸表の作成",
    categoryId: 16,
    page: 108
  },
  {
    id: "17-1",
    title: "本支店間の取引",
    categoryId: 17,
    page: 114
  },
  {
    id: "17-2",
    title: "本店と支店の損益勘定の記入",
    categoryId: 17,
    page: 115
  },
  {
    id: "18-1",
    title: "支配獲得日の連結修正仕訳",
    categoryId: 18,
    page: 120
  },
  {
    id: "18-2",
    title: "支配獲得後1年目の連結修正仕訳",
    categoryId: 18,
    page: 120
  },
  {
    id: "18-3",
    title: "支配獲得後2年目の連結修正仕訳",
    categoryId: 18,
    page: 121
  },
  {
    id: "19-1",
    title: "会社間取引の相殺消去",
    categoryId: 19,
    page: 124
  },
  {
    id: "19-2",
    title: "手形取引の修正",
    categoryId: 19,
    page: 124
  },
  {
    id: "19-3",
    title: "貸倒引当金の修正",
    categoryId: 19,
    page: 124
  },
  {
    id: "19-4",
    title: "未実現利益の消去①",
    categoryId: 19,
    page: 125
  },
  {
    id: "19-5",
    title: "未実現利益の消去②",
    categoryId: 19,
    page: 125
  },
  {
    id: "19-6",
    title: "連結精算表の作成①",
    categoryId: 19,
    page: 126
  },
  {
    id: "19-7",
    title: "連結精算表の作成②",
    categoryId: 19,
    page: 132
  },
]